import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import collapse from '@alpinejs/collapse'
import focus from '@alpinejs/focus'
import "../../vendor/aerni/livewire-forms/resources/dist/js/livewire-forms";
import 'focus-visible'

window.Alpine = Alpine
Alpine.plugin(collapse)
Alpine.plugin(focus)
Livewire.start()

